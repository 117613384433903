.crown {
    height: 35px !important;
    width: 35px !important;
    position: absolute !important;
    display: block;    
    top: -20px !important;
    left: 50% !important;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
    font-size: 30px;
    color: red;
}

.noCrown {
    display: none;
}
